var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-import",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submitimport
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('Download Format Import XLSX')))]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _vm._v(_vm._s(_vm.$t('Download')))], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('section', [_c('h3', {
    staticClass: "mt-1"
  }, [_vm._v("Import XLSX")]), _c('b-form-file', {
    staticClass: "mb-1",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onChange
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }